import React from "react";
import Typography from "@material-ui/core/Typography";
import style from "./Footer.module.css";
export default function TableFooter() {
  return (
    <footer className={style.root}>
      <ol className={style.items}>
        <li>
          <Typography color="textSecondary">Help</Typography>
        </li>
        <li>
          <Typography color="textSecondary">Send feedback</Typography>
        </li>
        <li>
          <Typography color="textSecondary">Privacy</Typography>
        </li>
        <li>
          <Typography color="textSecondary">Terms</Typography>
        </li>
      </ol>
    </footer>
  );
}
