import React from "react";
import Typography from "@material-ui/core/Typography";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import Video from "./Video";
import style from "./Gallery.module.css";

export default function Gallery({ videos, tag }) {
  return (
    <div className={style.root}>
      {videos.length > 0 ? (
        <div className={style.title}>
          <PersonalVideoIcon
            style={{ padding: "0" }}
            color="primary"
            fontSize="large"
          />
          <Typography color="textSecondary" className={style.txt}>
            {tag} videos
          </Typography>
        </div>
      ) : (
        ""
      )}
      <div className={style.gallery}>
        {videos.map(item => {
          return <Video key={item.id} item={item}></Video>;
        })}
      </div>
    </div>
  );
}
