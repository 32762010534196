import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Tag from './Tag/Tag'
import Home from './Home/Home'

export default function App() {
  return (
    <Router>
    <>      
      <Switch>
        <Route path="/influencer/:tag" component={Tag} />   
        <Route path="/" component={Home} />          
      </Switch>
    </>
  </Router>
  );
}