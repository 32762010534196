import React, { useState, useEffect } from "react";
import style from "./Suggestions.module.css";
export default function Suggestions({ history }) {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `https://liorshe.wixsite.com/instra/_functions/most_frequent`
        );
        const { results } = await response.json();
        setTags(results);
      } catch (e) {}
    })();
  }, []);
  return (
    <section className={style.root}>
      <ul>
        {tags.map(tag => {
          return (
            <li key={tag.name} className={style.element}>
              <img
                src={tag.image}
                width="157.5px"
                height="157.5px"
                alt={tag.name}
                onClick={() => {
                  history.push(`influencer/${tag.name}`, { tag });
                }}
              ></img>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
