import React, { useRef, useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as d3 from "d3";
import { VideoContext } from '../VideoContext'
import Gallery from "../Gallery/Gallery";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import style from "./Tag.module.css";

export default function Tag({
  history,
  match: { params },
  location: { state  }
}) {
  const canvas = useRef(null);
  const [tag, setTag] = useState(params.tag);
  const [data, setData] = useState({ children: [] });
  const [videos, setVideos] = useState([]);
  const [playingVideo, setPlayingVideo] = useState();
  const videoProviderValue = { playingVideo, setPlayingVideo }
  
  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

  
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `https://wix-mls.firebaseapp.com/ig/suggestions/${tag}`
        );
        const json = await response.json();
        localStorage.setItem(
          "tag",
          JSON.stringify({ image: json.user.profile_pic_url_hd, name: tag })
        );
        const dataToD3 = {
          children: json.suggestions.map((node, index) => {
            return {
              id: `id${index}`,
              Name: node.username,
              Count: node.count,
              image: node.profile_pic_url_hd
            };
          })
        };
        setData(dataToD3);
        draw(dataToD3);
      } catch (e) {}
    })();
  }, [tag]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `https://wix-mls.firebaseapp.com/ig/get/videos/${tag}`
        );
        const json = await response.json();
        setVideos([...json.videos, ...json.sidecars]);
      } catch (e) {}
    })();
  }, [tag]);

  function draw(dataset) {
    const canvasHeight = `${window.innerHeight * 0.6}px`;
    const canvasWidth = "100%";

    var bubble = d3
      .pack(dataset)
      .size([window.innerWidth, window.innerHeight * 0.6])
      .padding(15);

    const nodes = d3.hierarchy(dataset).sum(function(d) {
      return d.Count;
    });
    const bubbleData = bubble(nodes).descendants();

    var svg = d3
      .select(canvas.current)
      .append("svg")
      .attr("width", canvasWidth)
      .attr("height", canvasHeight)
      .attr("class", "bubble");

    svg
      .selectAll("pattern")
      .data(bubbleData)
      .enter()
      .append("pattern")
      .attr("id", d => `img_${d.data.id}`)
      .attr("width", 1)
      .attr("height", 1)
      .attr("patternUnits", "objectBoundingBox")
      .append("image")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", d => d.r * 2)
      .attr("height", d => d.r * 2)
      .attr("xlink:href", d => d.data.image);

    var node = svg
      .selectAll(".node")
      .data(bubbleData)
      .enter()
      .filter(function(d) {
        return !d.children;
      })
      .append("g")
      .attr("class", "node")
      .attr("transform", d => "translate(" + d.x + "," + d.y + ")");

    node.append("title").text(d => d.data.Name + ": " + d.data.Count);

    node
      .append("circle")
      .style("fill", d => `url(#img_${d.data.id})`)
      .attr("r", d => d.r)
      .on("click", d => {
        history.push(`${d.data.Name}`, { tag: {name: d.data.Name, image: d.data.image }});
        setTag(`${d.data.Name}`);
        setData({ children: [] });
      });

    /*
  node.append("text")
    .attr("dy", ".2em")
    .style("text-anchor", "middle")
    .text(function (d) {
      return d.data.Name.substring(0, d.r / 3);
    })
    .attr("font-family", "sans-serif")
    .attr("font-size", function (d) {
      return d.r / 5;
    })
    .attr("fill", "white");
 
  node.append("text")
    .attr("dy", "1.3em")
    .style("text-anchor", "middle")
    .text(function (d) {
      return d.data.Count;
    })
    .attr("font-family", "Gill Sans", "Gill Sans MT")
    .attr("font-size", function (d) {
      return d.r / 5;
    })
    .attr("fill", "white");
    */
  }

  function inlineStyle(data) {
    return `
          circle {
            stroke: #EEEEEE;
            stroke-width: 3px;
            transition: stroke-opacity 1s, stroke-width 1s, transform 1s;
          }
        
          text {
            font-size: 14px;
            font-weight: bold;
            fill: #fff;
            stroke: #000;
            stroke-width: 0;
          }
        
          circle:hover {
            cursor: pointer;
            stroke-opacity: 0;
            stroke-width: 0;
            transform: scale(1.2);
          }
          ${data
            .map(
              circle => `
          circle#${circle.id} {
            transform-origin: ${circle.x_axis} ${circle.y_axis};
          }
          `
            )
            .join("")}      
        `;
  }

  function getTag() {
    if (state) {
      return state.tag;
    } else {
      return { name: tag, image: "" };
    }
  }
  function renderView() {
    return (
      <>
        <Header tag={getTag()} history={history} />
        <style>{inlineStyle(data.children)}</style>
        <div className={style.wrapper}>
          <div className={style.d3} ref={canvas}></div>
          <div className={style.galleryRoot}>
          <VideoContext.Provider value={videoProviderValue}>
            <Gallery videos={videos} tag={tag}></Gallery>
          </VideoContext.Provider>
          </div>
        </div>
      </>
    );
  }

  return (    
    <>
      {data.children.length > 0 ? (
        renderView()
      ) : (
        <div className={style.container}>
        <div className={style.loadingWrapper}>
           {state ? <img src={state.tag.image} alt={state.tag.name}></img> : ''}
           <CircularProgress thickness={2} size={93} className={style.fabProgress} />
         </div>   
       </div>
      )}
    </>       
  );
}
