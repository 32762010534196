import React, { useRef, useContext } from "react"
import { VideoContext } from '../VideoContext'
import style from "./Video.module.css"
export default function Video({ item }) {
  const videoEl = useRef(null);
  const { playingVideo, setPlayingVideo } = useContext(VideoContext)
  const play = () => {
    if (playingVideo) {
      playingVideo.pause()
    }
    setTimeout(() => {      
      if (videoEl.current) {
        videoEl.current.play();
        setPlayingVideo(videoEl.current)
      }
    }, 1000)    
  };

  const pause = () => {
    if (videoEl.current) {
      videoEl.current.pause();
    }
  };

  return (
    <div onMouseEnter={play} onMouseLeave={pause} className={style.root}>
      <video
        width="160px"
        height="160px"
        ref={videoEl}
        poster={item.display_url}
        key={item.id}
        src={item.video_url}
        preload="metadata"
        loop
        className={style.video}
        type="video/mp4"
      ></video>
    </div>
  );
}
