import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Suggestions from "../Suggestions/Suggestions";
import Footer from "../Footer/Footer";
import style from "./Home.module.css";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "560px",
    borderRadius: "22px"
  },
  logo: {
    padding: "220px 0 20px"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  container: {
    display: "grid",
    justifyItems: "center",
    paddingBottom: "2.5rem"
  }
}));

export default function Home({ history }) {
  const classes = useStyles();
  const [text, setText] = useState("");

  const handleChange = event => {
    setText(event.target.value);
  };

  const handleSubmit = () => {
    history.push(`influencer/${text}`);
  };

  return (
    <div className={style.root}>
      <div className={classes.container}>
        <Typography component="div" className={classes.logo}>
          <Box fontSize={55} fontFamily="fontFamily">
            IGVerge
          </Box>
        </Typography>
        <form
          noValidate
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <FormControl>
            <Paper component="div" className={classes.root} elevation={10}>
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                value={text}
                onChange={handleChange}
                className={classes.input}
                placeholder="Explore influencers"
                inputProps={{ "aria-label": "explore influencers" }}
              />
            </Paper>
          </FormControl>
        </form>
        <Suggestions history={history} />
        <Footer />
      </div>
    </div>
  );
}
