import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import style from "./Header.module.css";
export default function Header({ history, tag }) {
  if (!tag.image) {
    tag = JSON.parse(localStorage.getItem("tag"));
  }
  return (
    <Breadcrumbs aria-label="breadcrumb" className={style.root}>
      <Link color="inherit" href="/">
        IGVerge
      </Link>
      <Link color="inherit">
        <img
          src={tag.image}
          width="87.5"
          height="87.5px"
          alt={tag.name}
          onClick={() => {
            history.push(`${tag.name}`, { tag });
          }}
        ></img>
      </Link>
      <Typography color="textPrimary">{tag.name}</Typography>
    </Breadcrumbs>
  );
}
